var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Configuriacíón Netpay")]),_c('v-spacer'),_c('AddNetpay',{attrs:{"currentIdCorp":_vm.currentIdCorp}})],1),_c('v-card-text',[_c('v-subheader',[_vm._v("Filtros")]),_c('v-row',[(this.$store.state.auth.user.admin === 1)?_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-select',{attrs:{"dense":"","outlined":"","append-icon":"mdi-database-outline","loading":_vm.selectLoading,"items":_vm.$store.state.auth.corps,"item-text":function (ref) {
	var name = ref.name;
	var idcorp = ref.idcorp;

	return (name + " (Corp " + idcorp + ")");
},"item-value":"idcorp","label":"Corporación seleccionada"},model:{value:(_vm.currentIdCorp),callback:function ($$v) {_vm.currentIdCorp=$$v},expression:"currentIdCorp"}}),(this.$store.state.auth.user.admin !== 1)?_c('v-spacer'):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.payments,"items-per-page":5,"search":_vm.search,"loading":_vm.dateTableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"success","disabled":item.active},on:{"change":function($event){return _vm.changeSwitch(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.sandbox",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","readonly":"","disabled":""},model:{value:(item.sandbox),callback:function ($$v) {_vm.$set(item, "sandbox", $$v)},expression:"item.sandbox"}})]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }