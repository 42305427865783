<template>
  <v-container>
    <v-card>
      <v-card-title>
        <span class="text-h5">Configuriacíón Netpay</span>
        <v-spacer/>
        <AddNetpay :currentIdCorp="currentIdCorp"/>
      </v-card-title>
      <v-card-text>
        <v-subheader>Filtros</v-subheader>
        <v-row>
          <v-col
            v-if="this.$store.state.auth.user.admin === 1"
            cols="12"
            md="6"
            sm="6"
          >
            <v-select
              dense
              v-model="currentIdCorp"
              outlined
              append-icon="mdi-database-outline"
              :loading="selectLoading"
              :items="$store.state.auth.corps"
              :item-text="({ name, idcorp }) => `${name} (Corp ${idcorp})`"
              item-value="idcorp"
              label="Corporación seleccionada"
            >
            </v-select>
            <v-spacer v-if="this.$store.state.auth.user.admin !== 1"></v-spacer>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="payments"
              :items-per-page="5"
              :search="search"
              :loading="dateTableLoading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.active`]="{ item }">
                <v-switch
                  v-model="item.active"
                  inset
                  color="success"
                  :disabled="item.active"
                  @change="changeSwitch(item)"
                ></v-switch>
              </template>
              <template v-slot:[`item.sandbox`]="{ item }">
                <v-switch
                  v-model="item.sandbox"
                  inset
                  readonly
                  disabled
                ></v-switch>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AddNetpay from '../../../components/configuration/neypay/AddNetpay.vue';
export default {
    name: "Netpay",
    components: {
        AddNetpay
    },
  data() {
    return {
      currentIdCorp: null,
      selectLoading: false,
      payments: [],
      search: "",
      headers: [
        { text: "Activo", value: "active" },
        { text: "Id", value: "id" },
        { text: "Alias", value: "alias" },
        { text: "Api Key", value: "api_key" },
        { text: "Secret Key", value: "secret_key" },
        { text: "Pruebas (Sandbox)", value: "sandbox" },
      ],
      dateTableLoading: false,
    };
  },
  watch: {
    currentIdCorp: function (val) {
      this.$store.dispatch("auth/setSelectedCorp", val);
      this.getPayments();
    },
  },
  mounted() {
    this.payments = [];
    this.currentIdCorp = this.$store.state.auth.user.id_corp;
  },
  methods: {
    getPayments() {
      this.dateTableLoading = true;
      this.payments = [];
      this.$root
        .post("/Admin/GetPayments")
        .then(
          (response) => {
            const info = response.data;
            if (info.success === "TRUE") {
              let data = info.Data;
              this.payments = data;
            } else {
              return this.$root.Toast("info", info.Msg);
            }
          },
          (error) => {
            this.$root.swalAlert(
              "error",
              "Error al cargar configuración",
              error.message
            );
          }
        )
        .finally(() => (this.dateTableLoading = false));
    },
    changeSwitch: async function (item) {
      let idActiveOld = null;
      this.payments.forEach((element) => {
        if (element.active && element.id !== item.id) {
          element.active = false;
          idActiveOld = element.id;
        }
      });
      if (idActiveOld === null) {
      }

      let confirmSwal = await this.$swal({
        title: "¿Esta seguro de cambiar?",
        text: "¡Solo puede tener un token activo!",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonText: "Si",
      });

      if (!confirmSwal.isConfirmed) {
        item.active = false;
        if (idActiveOld !== null) {
          this.restoreOldActive(idActiveOld);
        }
        return;
      }

      for (let i = 0; i < this.payments.length; i++) {
        if (this.payments[i].id !== item.id) this.payments[i].active = false;
      }

      this.$root
        .post("/Admin/updatePaymentActive", item)
        .then(
          (response) => {
            let data = response.data;
            if (data.success === "FALSE")
              return this.$root.swalAlert("error", data.message || data.Msg);

            this.$root.swalAlert(
              "success",
              "Se cambio la forma de pago activa"
            );
          },
          (error) => this.$root.swalAlert("error", error.message || error.Msg)
        )
        .finally(() => (this.loading = false));
    },
    restoreOldActive: function (idActiveOld) {
      let oldActive = this.payments.find(
        (element) => element.id === idActiveOld
      );
      oldActive.active = true;
    },
  },
};
</script>
