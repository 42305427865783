<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" dark fab v-bind="attrs" v-on="on">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card flat>
      <v-toolbar dark class="blue darken-4">
        <v-toolbar-title>Nueva Configuración Netpay</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 px-4">
        <v-form ref="formNewPayment" lazy-validation>
          <v-row>
            <v-col cols="12" class="mb-0 pb-0">
              <v-text-field
                label="Corporación para el pago"
                outlined
                disabled
                :value="getNameCorp"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-text-field
                v-model="payment.alias"
                name="alias"
                label="Alias"
                type="text"
                outlined
                :rules="[rules.required]"
                @keyup.enter="savePaymentValue"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-text-field
                label="Api Key"
                placeholder="Escribe la Api Key"
                v-model="payment.api_key"
                outlined
                :rules="[rules.required]"
                @keyup.enter="savePaymentValue"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-text-field
                label="Secret Key"
                placeholder="Escribe la Secret Key"
                v-model="payment.secret_key"
                outlined
                :rules="[rules.required]"
                @keyup.enter="savePaymentValue"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0">
              <v-switch
                v-model="payment.sandbox"
                class="mt-0 ml-1"
                label="Esta sera una clave para pruebas (Sandbox)"
                inset
                color="success"
                hide-details="auto"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-3 px-4">
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2"
          color="error"
          :loading="loading"
          @click="dialog = false"
        >
          <v-icon left dark>mdi-close-circle</v-icon>
          Cancelar
        </v-btn>
        <v-btn color="success" :loading="loading" @click="savePaymentValue">
          <v-icon left dark>mdi-check-circle</v-icon>
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AddNetpay",
  props: ["currentIdCorp"],
  data() {
    return {
      dialog: false,
      payment: {
        alias: "",
        api_key: "",
        secret_key: "",
        sandbox: false,
      },
      rules: {
        required: (v) => !!v || "Este campo es requerido",
      },
      loading: false,
    };
  },
  computed: {
    ...mapState("auth", ["corps"]),
    getNameCorp: function () {
      let currentCorp = this.corps?.[this.currentIdCorp];
      let name = currentCorp?.name + " (Corp " + currentCorp?.idcorp + ")";

      return currentCorp ? name : "";
    },
  },
  methods: {
    savePaymentValue: async function () {
      if (!this.$refs.formNewPayment.validate()) return;

      let confirmSwal = await this.$swal({
        title: "¿Esta seguro de guardar?",
        text: "¡Los datos no podrán ser cambiados!",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonText: "Si",
      });

      if (!confirmSwal.isConfirmed) return;

      this.loading = true;
      this.$root
        .post("/Admin/savePayment", this.payment)
        .then(
          (response) => {
            let data = response.data;
            if (data.success === "FALSE")
              return this.$root.swalAlert("error", data.message || data.Msg);

            this.dialog = false;
            this.$root.swalAlert("success", "Se guardo la nueva forma de pago");
            this.$emit("reloadPayments");
          },
          (error) => this.$root.swalAlert("error", error.message || error.Msg)
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
